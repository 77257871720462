import Layout from "../components/layout/Layout";
import PageNotFound from "../components/sections/PageNotFound";
import React from "react";

const NotFoundPage = () => {
  return (
    <Layout>
      <PageNotFound />
    </Layout>
  );
};

export default NotFoundPage;
