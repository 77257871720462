import { Button, Container } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "gatsby";
import React from "react";
import { faSearchLocation } from "@fortawesome/free-solid-svg-icons";

const PageNotFound = () => {
  return (
    <Container className="text-center">
      <FontAwesomeIcon
        icon={faSearchLocation}
        color="#efefef"
        size={"10x"}
        style={{ maxHeight: "160px", marginTop: "20px" }}
      />
      <h2 className="gold-header">404 - Seite nicht gefunden</h2>
      <Link to="/">
        <Button>Zur Startseite</Button>
      </Link>
    </Container>
  );
};

export default PageNotFound;
